import IIIF from './IIIF'

const resource = '/layer';

export default {
  get (manifest_id, layer_id) {
    return IIIF.get(`/${manifest_id}${resource}/${layer_id}`)
  },
  getByURL (url) {
    return IIIF.get(`${url}`)
  }
}