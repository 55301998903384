/* eslint-disable no-console */
import axios from 'axios'

//const baseURL = 'https://eiichi.shibusawa.or.jp/ebiki/getassoc/gss3';
const baseURL = '/ebiki/getassoc/gss3';

export default axios.create({
  baseURL: baseURL,
  dataType: 'xml',
  contentType: "text/xml; charset=UTF-8",
  timeout: 30000,
  processData: false
})