import IIIF from './IIIF'

const resource = '/list';

export default {
  get (manifest_id, list_id) {
    return IIIF.get(`/${manifest_id}${resource}/${list_id}`)
  },
  getByURL (url) {
    return IIIF.get(`${url}`)
  }
}