<template>
  <router-view
    :manifest_id="this.manifest_id"
    :manifest="this.manifest"
  ></router-view>
</template>

<script>
/* eslint-disable no-console */
import { Backend } from '@/ajax/Backend'
const iiifManifest = Backend.get('manifest')

export default {
  name: 'app',
  data: function () {
    return {
      manifest_id: 1,
      manifest: {}
    };
  },
  mounted() {
    // マニフェストの読み込み
    iiifManifest.get(this.manifest_id)
      .then(response => this.manifest = response.data)
      .catch(err => console.log(err))
  },
}
</script>
