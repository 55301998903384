import IIIF from './IIIF'

const resource = '/manifest';

export default {
  get (manifest_id) {
    return IIIF.get(`/${manifest_id}${resource}`)
  },
  getByURL (url) {
    return IIIF.get(`${url}`)
  }
}