<template>
  <div id="CONTENT">
    <tagline :sitename="sitename" :title="title"></tagline>
    <navidiv></navidiv>
    <!-- H1 -->
    <div class="h1-binder">
      <h1 class="b0">絵引データベース</h1>
    </div>
    <!-- /H1 -->
    <!-- コンテンツ -->
    <div id="CONTENT-BODY" class="no-padding-top">
      <!-- 説明文 -->
      <div class="main-description">
        <p>「実業史錦絵絵引データベース」は、錦絵内に描かれたモノ・道具・よそおい・人物などについて、絵や言葉で探したり、調べることができます。描かれたものを言葉に結びつけるとともに、言葉が表すものを錦絵で見ることができます。また、連想検索機能を使えば、関連した分類や作品を見つけることができます。</p>
      </div>
      <!-- /説明文 -->
      <!-- ボックス -->
      <div class="box-binder">
        <!-- begin -->
        <div class="base-skin">
          <h2 class="ebiki">絵引</h2>
          <div class="inner">
            <!-- 検索フォーム -->
            <form method="get" action="/annotations/search" id="SEARCH-FORM" name="Search">
              <input type="text" value="" name="keywords" class="keyword" /><input type="image" src="img/btn_search.gif" value="" alt="検索" name="" class="submit" />
            </form>
            <!-- /検索フォーム -->
            <p>錦絵に描かれた図像・事物を探す</p>
          </div>
        </div>
        <!-- end -->
        <!-- begin -->
        <div class="base-skin even">
          <h2 class="works">作品</h2>
          <div class="inner">
            <!-- 検索フォーム -->
            <form method="get" action="/works/assoc" id="SEARCH-FORM" name="assoc">
              <input type="text" value="" name="assoc_text" class="keyword" /><input type="image" src="img/btn_search.gif" value="" alt="検索" name="" class="submit" />
            </form>
            <!-- /検索フォーム -->
            <p>絵引に使われている錦絵を探す</p>
          </div>
        </div>
        <!-- end -->
        <!-- begin -->
        <div class="base-skin">
          <h2 class="category-index">カテゴリー索引</h2>
          <div class="inner">
            <p>絵引のカテゴリーを絞り込んで探す</p>
            <dl>
              <dt v-for="(layer, index) in layers" :key="index"><router-link :to="{ path: '/motifs/view/' + layer.id}">{{ layer.caption }}</router-link></dt>
            </dl>
          </div>
        </div>
        <!-- end -->
        <!-- begin -->
        <div class="base-skin even">
          <h2 class="series-index">シリーズ索引</h2>
          <div class="inner">
            <p>錦絵のシリーズ一覧から探す</p>
            <ul class="bullet">
              <li><a href="/series/view/1">{{ manifest.label }}</a></li>
            </ul>
          </div>
        </div>
        <!-- end -->
      </div>
      <!-- /ボックス -->
    </div>
    <!-- /コンテンツ -->
    <footerdiv></footerdiv>
  </div>
</template>

<script>
/* eslint-disable no-console */
// @ is an alias to /src
import tagline from '@/components/tagline.vue'
import navidiv from '@/components/navi.vue'
import footerdiv from '@/components/footer.vue'

export default {
  name: 'home',
  components: {
    tagline,
    navidiv,
    footerdiv
  },
  props: {
    sitename: { type: String, required: true },
    orgname: { type: String, required: true },
    manifest:  { type: Object, required: true },
    title:  { type: String, required: true },
    meta_description:  { type: String, required: true },
    meta_keywords:  { type: String, required: true },
  },
  computed: {
    layers: function() {
      let layers = []
      if (this.manifest.structures) {
        const members = this.manifest.structures[0].members
        members.forEach((_, i) => {
          let layer = {}
          layer.id = members[i].contentLayer.match(/\d+$/)[0]
          layer.caption = members[i].label
          layers.push(layer)
        });
      }
      return layers
    }
  },
  head: {
    title: function () {
      return {
        inner: this.sitename + ' ' + this.title + ' - ' + this.orgname,
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.meta_description },
        { name: 'keywords', content: this.meta_keywords },
        { property: 'og:title', content: this.sitename + ' ' + this.title + ' - ' + this.orgname },
        { property: 'og:description', content: this.meta_description },
      ]
    }
  }
}
</script>
