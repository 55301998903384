import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import VueGtag from "vue-gtag";

Vue.use(VueRouter);

const sitename = "実業史錦絵絵引 絵引データベース";
const orgname = "実業史錦絵絵引　渋沢栄一記念財団";
const meta_description =
  "明治初期の実業史錦絵を索引化。錦絵内に描かれた道具・よそおい・人物などを絵から検索できる『実業史錦絵絵引データベース』や、FLASHで楽しむ『実業史錦絵絵引ギャラリー』など。渋沢栄一記念財団実業史研究情報センターが運営。";
const meta_keywords =
  "錦絵,絵引,浮世絵,渋沢栄一,絵引ギャラリー,絵引データベース,絵引DB";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    props: {
      title: "トップページ",
      sitename: sitename,
      orgname: orgname,
      meta_description: meta_description,
      meta_keywords: meta_keywords,
    },
  },
  {
    path: "/series/view/:id",
    name: "series",
    component: () => import("../views/Series.vue"),
    props: (route) => ({
      series_id: Number(route.params.id),
      title: "シリーズ索引",
      sitename: sitename,
      orgname: orgname,
      meta_description: meta_description,
      meta_keywords: meta_keywords,
    }),
  },
  {
    path: "/motifs/view/:id",
    name: "motif",
    component: () => import("../views/Motif.vue"),
    props: (route) => ({
      motif_id: Number(route.params.id),
      title: "カテゴリー索引",
      sitename: sitename,
      orgname: orgname,
      meta_description: meta_description,
      meta_keywords: meta_keywords,
    }),
  },
  {
    path: "/works/view/:id",
    name: "work",
    component: () => import("../views/Work.vue"),
    props: (route) => ({
      work_id: Number(route.params.id),
      title: "作品詳細",
      sitename: sitename,
      orgname: orgname,
      meta_description: meta_description,
      meta_keywords: meta_keywords,
    }),
  },
  {
    path: "/annotations/view/:id",
    name: "annotation",
    component: () => import("../views/Annotation.vue"),
    props: (route) => ({
      annotation_id: Number(route.params.id),
      title: "図像・事物詳細",
      sitename: sitename,
      orgname: orgname,
      meta_description: meta_description,
      meta_keywords: meta_keywords,
    }),
  },
  {
    path: "/annotations/search/",
    name: "annotations_search",
    component: () => import("../views/AnnotationsSearch.vue"),
    props: {
      title: "絵引（検索結果）",
      sitename: sitename,
      orgname: orgname,
      meta_description: meta_description,
      meta_keywords: meta_keywords,
    },
  },
  {
    path: "/works/assoc/",
    name: "assoc",
    component: () => import("../views/Assoc.vue"),
    props: {
      title: "作品連想検索結果",
      sitename: sitename,
      orgname: orgname,
      meta_description: meta_description,
      meta_keywords: meta_keywords,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

Vue.use(
  VueGtag,
  {
    config: { id: "G-6QHTGHHQGW" },
  },
  router
);

export default router;
