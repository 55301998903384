<template>
  <!-- タグライン -->
  <div id="TAGLINE">
    <p>{{ sitename }} {{ title }}</p>
    <ul>
      <li><a href="//ebiki.jp/support/outline.html">このサイトについて</a></li>
      <li><a href="//ebiki.jp/support/sitemap.html">サイトマップ</a></li>
      <li><a href="//ebiki.jp/help/">絵引の見方・使い方</a></li>
    </ul>
  </div>
  <!-- /タグライン -->
</template>

<script>
export default {
  name: 'tagline',
  props: {
    sitename: String,
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
