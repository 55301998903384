import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueHead from 'vue-head'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VuePaginate from 'vue-paginate'
Vue.use(VuePaginate)
import moment from 'vue-moment'
Vue.use(moment)

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueHead, {
  separator: ''
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
