import IIIF from './IIIF'

const resource = '/canvas';

export default {
  get (manifest_id, canvas_id) {
    return IIIF.get(`/${manifest_id}${resource}/${canvas_id}`)
  },
  getByURL (url) {
    return IIIF.get(`${url}`)
  }
}