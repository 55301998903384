import iiifManifest from './iiifManifest'
import iiifCanvas from './iiifCanvas'
import iiifLayer from './iiifLayer'
import iiifAnnotationList from './iiifAnnotationList'
import iiifAnnotation from './iiifAnnotation'

import GETAssocWork from './GETAssocWork.js'

const repositories = {
  manifest: iiifManifest,
  canvas: iiifCanvas,
  layer: iiifLayer,
  list: iiifAnnotationList,
  annotation: iiifAnnotation,
  work: GETAssocWork
};

export const Backend = {
  get: name => repositories[name]
};