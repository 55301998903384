import GETAssoc from './GETAssoc'

const builder = require('xmlbuilder')
const xml2js = require('xml2js')
const assoc_attr = {
  target: 'ebiki',
  narticles: 10,
  nkeywords: 20,
  'a-offset': 0,
  'a-props': 'title,img,author'
};

export default {
  async assoc (config = [], freetext = '', articles = [], words = []) {
    const request = builder.create('gss', { encoding: 'utf-8' })
      .att('xmlns', 'http://gss3.cs.nii.ac.jp/')
      .att('version', '3.0')
    if (config) {
      Object.assign(assoc_attr, config)
    }
    const assoc = request.ele('assoc', assoc_attr)
    if (freetext) {
      assoc.ele('freetext', freetext)
    }
    if (articles) {
      articles.forEach(function( value ) {
        assoc.ele('article', {name: value})
      });
    }
    if (words) {
      words.forEach(function( value ) {
        assoc.ele('article', {vec: '[["'+value+'",1]]'})
      });
    }
    const request_xml = request.end()
    console.log(request_xml)
    const result_xml = await GETAssoc.post(`/`, request_xml)
    console.log(result_xml.data)
    const response = xml2js.parseStringPromise(result_xml.data, {trim: true, explicitArray: false}).then(function(result) {
      return result
    })
    .catch(function(err) {
      console.log(err)
    });
    return response
  }
}